/* .login-bg {
 width:100vw;
 height: 100vh; 
 position: relative;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 background: linear-gradient(rgba(0,0,0,0.5), rgba(0, 0, 0, 0.5)), url('../../assets/bg_login.png') no-repeat center center fixed;
} */


.login-bg{
  width:100vw;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0, 0, 0, 0.5)), url('../../assets/bg_login.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-card {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100vw;
}

.ffg-logo {
  max-width: 300px;
  width: 70%;
}

.pm-code-bg {
  background-color: rgba(252, 222, 124, 0.767) !important;
}
