.App {
  text-align: center;
  height: 100vh;
  /* width: 100vw; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2, h3, h4, h5, h6 {
  color: rgb(40, 63, 123);
  font-weight: bold;
  text-align: left;
}

.fb-red {
  color: rgb(204, 0, 16) !important;
}

.fb-blue {
  color: rgb(40, 63, 123) !important;
}

.dot-line{
  border-top: 2px dotted rgb(130, 139, 160);
  margin: 16px 0px;
  border-radius: 0px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  opacity: 1;
  display: block;
  background-color: transparent;
}

.ffg-form, .ffg-form-narrow {
  /* max-width: 640px;   
  margin-right: auto; */
}

.ffg-form label {
  text-align: left;
  font-weight: bold;
  padding: 16px;
  font-size: 110%;
}

.ffg-form-narrow label {
  text-align: left;
  font-weight: bold;
  font-size: 110%;
}

.label-narrow {
  text-align: left;
  font-weight: bold;
  padding: 0px !important;
  font-size: 110%;
}

.ffg-form input {
  padding: 16px;
}

.ffg-form select {
  padding: 16px;
}

.subscript {
  font-size: 0.8em;
}

.StepWizardStyle {
  height: 100vh;
  > div {
    height: 100vh;
    > div {
      height: 100vh;
    }
  }
}

