.btn-wizard {
    text-decoration: none;
    display: inline-block;
    color: #fff;
    font-weight: bold;
    background-color: rgb(204, 0, 16);
    border-color: rgb(204, 0, 16);
}

.btn-wizard:hover {
    transition-duration: 0.4s;
    color:rgb(204, 0, 16);
    background-color: rgb(255, 255, 255);
}

.btn-outline-wizard {
    text-decoration: none;
    display: inline-block;
    color: rgb(204, 0, 16);
    font-weight: bold;
    border-color: rgb(204, 0, 16);
}

.btn-outline-wizard:hover {
    transition-duration: 0.4s;
    color: rgb(255, 255, 255);
    background-color: rgb(204, 0, 16);
}