/* body {
    
} */

.MGM-bg {
    /* width:100vw;
    height: 100vh; */
    /* background: linear-gradient(rgba(0,0,0,0.5), rgba(0, 0, 0, 0.5)); */

    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
    background-color: #ca2728;
    padding: 0;
    margin: 0;
}

.login-card {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100vw;
}

.ffg-logo {
    max-width: 300px;
    width: 70%;
}

.pm-code-bg {
    background-color: rgba(252, 222, 124, 0.767) !important;
}

.MGM-cover {
    border-radius: 10px;
}

.ffg-nav-link {
    width:100%;
    background: none;
    border: 2px solid #df9216;
    border-radius: 15px;

    display: block;
    padding: 4px 2px;
    /* font-size: var(--bs-nav-link-font-size); */
    /* font-weight: var(--bs-nav-link-font-weight); */
    color: #000000;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.ffg-nav-link.active,
.show>.ffg-nav-link {
    color: #ca2728;
    font-weight: bold;
    background-color: #ffd96e;
} 
