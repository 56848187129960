.pro-header{
 color: rgb(204, 0, 16);
}

.pro-bg {
    background-color: #fffedc;
    padding-bottom: 15px;
    border-radius: 10px;
}

.pro-sample-table {
    width: 100%;
    font-size: 12px;
    padding: 3px;
}

.pro-sample-table thead th {
    background-color: rgb(220, 220, 220) !important;
}

.pro-sample-table thead th, .pro-sample-table tbody td
{
    background-color: #fff;
    padding: 3px;
    border: 1px solid rgb(50, 50, 50);
}

.pro-sub-head {
    color: ff0000;
}
/* .pro-sample-table tr td {
    padding: 3px;
    border: 1px solid rgb(50, 50, 50);
} */
