.ffg-uploader-sample {
    max-width: 100px;
}

.ffg-uploader-loading {
    background-color: rgb(155 155 155 / 70%);
    position: absolute;
    width: 100%;
    max-height: 170px;
    height: 100%;
    z-index: 999;
}

.ffg-uploader-loading-icon-container {
    top: calc(50% - 42px);
    position: relative;
}

.id-uploader-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    font-size: 0;
    opacity: 0;
}

.ffg-uploader-preview {
    text-align: center;
    width: 40vw;
    max-height: 170px;
    background: #C4C4C4;
    border-radius: 4px;
}

.ffg-uploader-preview img {
    padding: 0px;
    width: 100%;
    max-height: 160px;
    width: calc(100% - 15px);
}

.ffg-uploader-preview span {
    position: absolute;
    bottom: 10px;
    /* left: 120px; */
    left: calc(100% - 46px);
}

.ffg-uploader-process {   
    font-size: 180% !important;
    left: calc(100% - 40%) !important; 
}

.ffg-uploader-card {
    text-align: center;
    width: 40vw;
    max-height: 170px;
    background: #C4C4C4;
    opacity: 1;
    border-radius: 4px;
}

.ffg-uploader-btn {
    display: inline-flex;
    position: relative;
    max-width: 260px;
    max-height: 170px;
    padding: 5px 0;
}

.ffg-uploader-card img {
    padding-top: 5px;
    max-height: 170px;
    width: calc(100% - 15px);
}

.ffg-uploader-btn p {
    font-size: 14px;
    font-weight: bold;
    line-height: 0px;
    color: #FFFFFF;
    padding-top: 12px;
}