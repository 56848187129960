.flag {
    max-width: 90px;
    width: 50%;
}

.btn-idcard {
    background-color: rgb(249, 249, 249);
    color:rgb(40,63,123);
    font-weight: bold;
    border: rgb(40,63,123) solid 1px;
    border-radius: 4px;
    width: 100%;
    min-height: 180px;
}

.btn-idcard span {
    font-size: 26px;
}

.btn-idcard-other {
    min-height: 80px !important;
    background-color: rgb(235, 235, 235);
}