.Signature-Pad > canvas {
    background: #fffbe8;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;   
    aspect-ratio: auto 4/3;
}


 @media (min-width: 992px){
     .Signature-Pad > canvas {
        max-height: 350px;
    } 
}